import React, { Component } from 'react'
import t from '../../service/translate.service'
import { Link } from 'react-router-dom'
import { Input } from '../../components/formComponents'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import ResetPasswordStore from '../../store/ResetPassword.store'
import { Form, Button } from 'react-bootstrap'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import withRouter from '../../components/withRouter'
import historyService from '../../service/history.service'
import openedEye from '../../assets/eye-regular.png'
import closedEye from '../../assets/eye-slash-solid.png'
import { Alert } from 'antd'

class ResetPasswordPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showPassword: false,
    }

    this.toggleVisibility = this.toggleVisibility.bind(this)

    extendObservable(this, {
      resetPasswordStore: new ResetPasswordStore()
    })
    this.resetPasswordStore.checkResetLink(props.useParams.hash)
  }

  toggleVisibility () {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }))
  }

  onSubmit = e => {
    e.preventDefault()
    this.resetPasswordStore.submit()
  }

  renderForm = () => {
    return (
      <div>
        {this?.resetPasswordStore?.passwordErr && <Alert message={this?.resetPasswordStore?.passwordErr} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <Form onSubmit={this.onSubmit}>
          <div className='login-password-group form-group'>
            <Input
              value={this.resetPasswordStore.model.password}
              onChange={e =>
                this.resetPasswordStore.setVal('password', e.target.value)
              }
              validate={this.resetPasswordStore.validators.password}
              label={t['USER_PASSWORD']}
              type={this.state.showPassword ? 'text' : 'password'}
            />
            <img className='loginShowPassword' src={this.state.showPassword ? openedEye : closedEye} onClick={this.toggleVisibility} alt='' />
          </div>
          <div className='login-password-group form-group'>
            <Input
              value={this.resetPasswordStore.model.confirmPassword}
              onChange={e =>
                this.resetPasswordStore.setVal('confirmPassword', e.target.value)
              }
              label={t['USER_PASSWORD_CONFIRM']}
              type={this.state.showPassword ? 'text' : 'password'}
            />
            <img className='loginShowPassword' src={this.state.showPassword ? openedEye : closedEye} onClick={this.toggleVisibility} alt='' />
          </div>
          <Form.Group className='actions text-center'>
            <Link to='/login'
            onClick={() => historyService.push('/login')}>
              {t.ALREADY_HAVE_ACCOUNT}
            </Link>
            <div className='text-center'>
              <Button type='submit' className='button primary'>
                {t.FORM_SUBMIT}
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    )
  }

  render () {
    return (
      <div className='subpageContent'>
        <div className='title'>{t.CHANGE_PASSWORD}</div>
        {(() => {
          switch (this.resetPasswordStore.status) {
            case 'SUCCESS':
              return (<ThankYouOverlay
                title={t.PASSWORD_CHANGED_SUCCESS}
                description={t.PASSWORD_CHANGED}
                buttonText={t.REGISTRATION_BACK_TO_LOGIN}
                buttonLink='/login'
              />)
            case 'INVALID_HASH':
              return (<Alert message={t.LINK_EXPIRED} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />)
            case 'INITIAL':
            case 'PASSWORD_ERROR':
              return (
                <div>
                  <div className='subtitle'>
                    {t.PASSWORD_RESET_TEXT}
                  </div>
                  {this.renderForm()}
                </div>
              )
            default:
              return <LoadingPageOverlay />
          }
        })()}
      </div>
    )
  }
}

export default withRouter(observer(ResetPasswordPage))
