import React from 'react'
import { observer } from 'mobx-react'
import pdfImg from '../../assets/pdf-img.png'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'

const PreviewItem = ({ file, removeFile, isLoyaltyUpload }) => {
  return (
    <div key={file.preview} className='item'>
      <div className='container'>
        <div className='box'>
          <div className='image'>
            <img
              src={file.type === 'application/pdf' ? pdfImg : URL.createObjectURL(file)}
              alt='file upload preview'
            />
          </div>
          {!isLoyaltyUpload &&
          (<span className='remove-icon' onClick={e => removeFile(file)}>
            <span className='icon'>×</span>
          </span>)}
        </div>
      </div>
    </div>
  )
}
PreviewItem.propTypes = {
  file: PropTypes.object,
  removeFile: PropTypes.func,
  isLoyaltyUpload: PropTypes.bool
}
const UploadPreview = ({ files, onImageDrop, label, buttonText, accept }) => {
  function removeFile (file) {
    const i = files.indexOf(file)
    files.splice(i, 1)
  }

  const previewMarkup = files && files.map(file => (
    <PreviewItem
      key={file.name}
      file={file}
      removeFile={() => removeFile(file)}
      isLoyaltyUpload={!!buttonText}
    />
  ))

  const applyStyleToBox = buttonText ? { display: 'contents', textAlignLast: 'center' } : {}

  return (
    <div className='upload-preview' style={ buttonText ? { marginBottom: '26px' } : {} }>
      {previewMarkup}
      <div className='item'>
        <div className='container'>
          <div className='box' style={applyStyleToBox}>
            <Dropzone
              className='drop-button'
              multiple
              accept={accept || {'image/*': [], 'application/pdf': ['.pdf']}}
              onDrop={onImageDrop}
            >
            {({ getRootProps, getInputProps }) => {
              return (
                <div>
                <div {...getRootProps()} >
                  <input {...getInputProps()}/>
                <div className={`icon ${buttonText && 'button primary btn btn-primary'}`}>{buttonText || <span style={{fontSize: '65px', color: '#c4c4c4'}}>+</span>}</div>
                </div>
                </div>
            )}}
            </Dropzone>
          </div>
          {label && <div className='label'>{label}</div>}
        </div>
      </div>
    </div>
  )
}
UploadPreview.propTypes = {
  files: PropTypes.array,
  onImageDrop: PropTypes.func,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  accept: PropTypes.string
}
export default observer(UploadPreview)
