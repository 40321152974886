import React from 'react'
import PrimaryTitle from '../../components/PrimaryTitle'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import { Link } from 'react-router-dom'

const ErrorOverlay = ({ buttonLink, handleClick }) => {
  return (
    <div className='thank-you-overlay'>
      <div className='wrapper'>
        <PrimaryTitle title='Error' />
        <div className='description'>{t.ERROR}</div>
        {buttonLink && <Link to={buttonLink} className='button primary' onClick={() => handleClick()}>Ok</Link>}
      </div>
    </div>
  )
}
ErrorOverlay.propTypes = {
  buttonLink: PropTypes.string,
  handleClick: PropTypes.func
}
export default ErrorOverlay
