import get from 'lodash/get'
import { api, API_BASE } from '../service/api.service'
import { extendObservable, action } from 'mobx'
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import t from '../service/translate.service'
import UserStore from '../store/user.store'
import { buildValidators, required } from '../service/validate'

const CONSULTATION_ENDPOINT = `${API_BASE}/api/consultation`
const SCHEDULE_ENDPOINT = `${API_BASE}/api/schedule`

class ConsultationStore {
  constructor (contractStore) {
    extendObservable(this, {
      contractStore,
      loading: false,
      consultations: [],
      selectedConsultation: {},
      error: null,
      closestConsultation: null,
      submitted: false,
      success: false,
      users: [],
      managers: []
    })
    this.scheduleConsultation = this.scheduleConsultation.bind(this)
    UserStore.addOnUserChangeHandler(user => {
      if (!user) {
        return this.consultations.clear()
      }
      this.getAll()
    })
  }
  async getAll (selectedContractNumber) {
    if (selectedContractNumber) {
      this.contractStore.selectedContract = { contractNumber: selectedContractNumber }
      this.getUsersForContract(selectedContractNumber)
    }
    if (!this.contractStore.ready || !this.contractStore.selectedContract) {
      return
    }
    this.loading = true
    this.error = null
    const contractNumber = this.contractStore.selectedContract.contractNumber
    try {
      const response = await api.post(CONSULTATION_ENDPOINT + '/get-all', { contractNumber })
      this.consultations = extractResponse(response)
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.consultations.clear()
    }

    this.loading = false
  }

  async getPropertyManagers (selectedContractNumber) {
    
    if (selectedContractNumber) {
      this.contractStore.selectedContract = { contractNumber: selectedContractNumber }
      this.getUsersForContract(selectedContractNumber)
    }
    if (!this.contractStore.ready || !this.contractStore.selectedContract) {
      return
    }
    this.loading = true
    this.error = null
    const contractNumber = this.contractStore.selectedContract.contractNumber
    try {
      const response = await api.get(`/api/contract/${contractNumber}/property-managers`)
      this.managers = extractResponse(response)
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.managers.clear()
    }

    this.loading = false
  }

  get validators () {
    return buildValidators(
      {
        subject: [required],
        timeslot: [required]
      },
      this.submitted
    )
  }

  handleMultipaneListItemClick = consultation => {
    this.selectedConsultation = consultation
  }

  async scheduleConsultation (schedule, subject, userEmail = UserStore.user.email, userPhone = UserStore.user.phone) {
    this.loading = true
    try {
      const response = await api
        .post(SCHEDULE_ENDPOINT, {
          contractNumber: this.contractStore.selectedContract.contractNumber,
          date: schedule.date,
          time: schedule.startTime + ':00',
          subject,
          employees: schedule.employees,
          location: schedule.location,
          userEmail,
          userPhone,
          userId: schedule.userId
        })
      this.submitted = false
      this.loading = false
      this.success = true
      schedule.contractNumber = response.contractNumber
      schedule.userId = response.userId
      this.getAll()
    } catch (err) {
      let error = t[extractError(err)] || extractError(err)
      const data = get(err, 'response.data.data')
      if (data) {
        error = `${error} ${data}`
      }
      this.error = error
      this.loading = false
    }
  }

  unScheduleConsultation = action((scheduleId, schedule) => {
    return api
      .delete(SCHEDULE_ENDPOINT + '/' + scheduleId, { data: {
        employees: schedule.employees
      } })
      .then(response => {
        const responseData = extractResponse(response)
        schedule.contractNumber = response.contractNumber
        schedule.userId = response.userId
        this.getAll()
        return responseData
      })
      .catch(err => {
        const msg = extractError(
          err,
          'Error occured when trying to schedule consultation.'
        )
        this.loginError = msg
        throw msg
      })
  })

  expandConsultation = (location, consultationDate, consultationTimeFrom) => {
    this.consultations = this.consultations.map(c => {
      if (c.location === location &&
        c.date === consultationDate &&
        c.timeFrom === consultationTimeFrom) {
        c.expanded = true
      } else {
        c.expanded = false
      }
      return c
    })
  }

  async getUsersForContract (selectedContract) {
    try {
      const res = await api.get('/api/user/contract/' + selectedContract)
      this.users = res.data
    } catch (err) {
      throw err
    }
  }
}

export default ConsultationStore
