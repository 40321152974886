import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import t from '../../service/translate.service'
import { inject, observer } from 'mobx-react'
import propTypes from 'prop-types'
import find from 'lodash/find'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { ContractsImage } from '../../components/icons'
import NoContent from '../../components/NoContent'
import ContractList from './ContractList'
import withRouter from '../../components/withRouter'
import historyService from '../../service/history.service'

class ContractsPage extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    props.contractStore.fetchContracts();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const pending = find(this.props.contractStore.contracts, { valid: false });
    return (
      <div>
        {this.props.contractStore.loading && <LoadingPageOverlay />}
        {this.props.contractStore.selectedContract ? (
          <ContractList />
        ) : (
          !this.props.contractStore.selectedContract &&
          !pending && (
            <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
          )
        )}
        <div className="text-center">
          <Link
            to="/contracts/add-contract"
            className="button primary"
            style={{ marginBottom: "30px" }}
            onClick={() => historyService.push("/contracts/add-contract")}
          >
            {t.ADD_A_CONTRACT_BUTTON}
          </Link>
        </div>
      </div>
    );
  }
}

export default inject('contractStore')(withRouter(observer(ContractsPage)))
