import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
// import { extendObservable } from 'mobx'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import LoyaltyPaymentCashOut from './LoyaltyPaymentCashOut'
import LoyaltyPaymentDeposit from './LoyaltyPaymentDeposit'
import LoyaltyPaymentKeepPoints from './LoyaltyPaymentKeepPoints'
import history from '../../service/history.service'

class LoyaltyPaymentInitialMessage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleCashOut = () => {
    this.props.store.cashOut = true
  }
  handleDeposit = () => {
    this.props.store.deposit = true
  }
  handleKeepPoints = () => {
    this.props.store.keepPoints = true
  }
  OnCancelLoyaltyPayment = () => {
    this.props.store.loyaltyPayment = false
  }
  onCancelCashOut = () => {
    this.props.store.cashOut = false
    this.props.store.iban = ''
    this.props.store.confirmIban = false
    this.props.store.email = ''
    this.props.store.password = ''
    this.props.store.cashOutError = ''
  }
  onCancelDeposit = () => {
    this.props.store.deposit = false
  }
  onCancelKeepPoints = () => {
    this.props.store.keepPoints = false
  }
  goBack = () => {
    this.props.navigate(-2)
  }

  render() {
    const { store } = this.props
    const { cashOut, deposit, keepPoints, redeemPoints, pointsToEuro } = store

    if (cashOut) {
      return (
        <LoyaltyPaymentCashOut
          store={store}
          onCancel={this.onCancelCashOut}
          onRedeem={redeemPoints}
          goBack={this.goBack}
        />
      )
    }

    if (deposit) {
      return (
        <LoyaltyPaymentDeposit
          store={store}
          onCancel={this.onCancelDeposit}
        />
      )
    }

    if (keepPoints) {
      return (
        <LoyaltyPaymentKeepPoints
          store={store}
          onCancel={this.onCancelKeepPoints}
        />
      )
    }

    return (
      <div className='loyalty-upload module'>
        <div className='loyalty-code-top text-center'>
          <PrimaryTitle title={t.LOYALTY_INITIAL_MESSAGE_TITLE} />
          <p>{t.LOYALTY_INITIAL_MESSAGE_TEXT1 + pointsToEuro.toFixed(2).replace('.', ',') + t.LOYALTY_INITIAL_MESSAGE_TEXT2}</p>
          <p>{t.LOYALTY_INITIAL_MESSAGE_TEXT3}</p>
        </div>
        <Form>
          <Form.Group className='actions text-center'>
            <div className='group'>
              <p style={{ marginBottom: '10px' }}>{t.LOYALTY_INITIAL_MESSAGE_TEXT4}</p>
              <Button className='button primary' onClick={this.handleCashOut}>{t.LOYALTY_INITIAL_MESSAGE_BTN1}</Button>
              {/* <Button className='button primary' onClick={this.handleDeposit}>{t.LOYALTY_INITIAL_MESSAGE_BTN2}</Button> */}
              <Button className='button primary' onClick={this.handleKeepPoints}>{t.LOYALTY_INITIAL_MESSAGE_BTN3}</Button>
            </div>
          </Form.Group>
        </Form>
        <Button className='button tertiary' onClick={(e) => this.OnCancelLoyaltyPayment(e)}>{t.FORM_CANCEL}</Button>
      </div>
    )
  }
}

LoyaltyPaymentInitialMessage.propTypes = {
  store: PropTypes.object
  // handleClick: PropTypes.func
}

export default observer(LoyaltyPaymentInitialMessage)
