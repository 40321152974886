import { extractError, extractErrorFromSap } from '../service/response.service'
import { api, API_BASE } from '../service/api.service'
import { extendObservable } from 'mobx'
import t from '../service/translate.service'
import User from '../store/user.store'
import {
  buildValidators,
  requiredCheckbox
} from '../service/validate'

class LoyaltyStore {
  constructor() {
    extendObservable(this, {
      user: User.user,
      userRegistered: false,
      userQueuedUp: false,
      model: {
        contractNumber: '',
        terms: false
      },
      pointsList: {
        totalPoints: 0,
        rows: []
      },
      contractSelected: false,
      errorTitle: '',
      error: '',
      unregisterError: '',
      accountError: '',
      loyaltyProgramView: {},
      submitted: false,
      loading: false,
      showSuccessMessage: true,
      files: [],
      uploaded: false,
      uploadError: '',
      previewImage: false,
      code: '',
      redeemCode: false,
      codeUploaded: false,
      codeUploadError: '',
      pointsAwarded: null,
      loyaltyPayment: false,
      cashOut: false,
      deposit: false,
      keepPoints: false,
      iban: '',
      confirmIban: false,
      email: '',
      password: '',
      createdAt: '',
      pointsToEuro: 0,
      showThankYou: false,
      cashOutError: ''
    })

    this.submitRedeemCode = this.submitRedeemCode.bind(this)
    this.redeemPoints = this.redeemPoints.bind(this)

    if (this.user.registeredToLoyaltyProgram) {
      this.userRegistered = this.user.registeredToLoyaltyProgram
      this.getLoyaltyProgramViewData(this.user.loyaltySerialNumber)
    }
  }

  get validators() {
    return buildValidators(
      {
        terms: [requiredCheckbox]
      },
      this.submitted
    )
  }

  setCode = (value) => {
    this.code = value
    this.codeUploadError = value ? '' : t.LOYALTY_CODE_EMPTY
  }
  setVal = (field, value) => {
    this.model[field] = value
  }

  setIban = (value) => {
    this.iban = value
  }

  setEmail = (value) => {
    this.email = value
  }

  setPassword = (value) => {
    this.password = value
  }

  toggleConfirmIban = () => {
    this.confirmIban = !this.confirmIban
  }

  toggleShowAll = () => {
    this.pointsList.rows = this.loyaltyProgramView.account_lines
  }

  contractSelect = (contractNumber) => {
    this.setVal('contractNumber', contractNumber)
    this.contractSelected = false
    this.contractInUse = false
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.submitted = true
    if (!this.model.contractNumber) {
      this.contractSelected = true
    } else {
      if (!this.model.terms) return
      this.checkContract(this.model.contractNumber)
      // this.loyaltyOfflineRegister(this.model.contractNumber)
    }
  }

  async loyaltyOfflineRegister(contractNumber) {
    this.loading = true
    try {
      const queue = await api.post(`${API_BASE}/api/loyalty-offline/register`, { contractNumber })

      console.log(queue.data)
      this.userRegistered = true
      this.user.registeredToLoyaltyProgram = true
      this.userQueuedUp = true
      this.loading = false
      window.scrollTo(0, 0)
    } catch (err) {
      console.log(extractError(err))
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  async checkContract(contractNumber) {
    this.loading = true
    try {
      const res = await api.get(`${API_BASE}/api/loyalty/${contractNumber}/check`)
      if (!res.data.inUse) {
        this.registerUserForLoyalty(contractNumber)
      } else {
        this.error = t.CONTRACT_ALREADY_IN_USE
        this.errorTitle = t.CONTRACT_ALREADY_IN_USE_TITLE
        this.loading = false
        window.scrollTo(0, 0)
      }
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  async getLoyaltyProgramViewData(loyaltySerialNumber) {
    this.loading = true
    try {
      const res = await api.get(`${API_BASE}/api/loyalty/${loyaltySerialNumber}/account`)
      const contract = this.user.contracts.find((c) => c.contractNumber.includes(res.data.contract))
      if(contract) {
        this.model.contractNumber = contract.contractNumber
        this.getRegistrationDate(this.model.contractNumber)
        this.loyaltyProgramView = res.data
        this.pointsList.totalPoints = res.data.total_points
        this.pointsList.rows = res.data.account_lines.slice(0, 19)
        this.loading = false
        this.pointsToEuro = res.data.total_points / 120
      } else {
        this.accountError = t['SOMETHING_WENT_WRONG']
        this.errorTitle = t['SOMETHING_WENT_WRONG']
        this.loading = false
      }
    } catch (err) {
      console.log(extractError(err))
      this.accountError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.errorTitle = t[extractError(err) + '_TITLE']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  async registerUserForLoyalty(contractNumber, serialNumber) {
    try {
      const res = await api.post(`${API_BASE}/api/loyalty/register`, { contractNumber, serialNumber })
      this.userRegistered = true
      this.user.loyaltySerialNumber = res.data.loyaltySerialNumber
      this.getLoyaltyProgramViewData(res.data.loyaltySerialNumber)
    } catch (err) {
      console.log(extractError(err))
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.errorTitle = t[extractError(err) + '_TITLE']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  async unregisterFromLoyaltyProgram() {
    this.loading = true
    try {
      await api.put(`${API_BASE}/api/loyalty/${this.user.loyaltySerialNumber}/unregister`)
      this.model.contractNumber = ''
      this.model.terms = false
      this.userRegistered = false
      this.submitted = false
      this.loading = false
    } catch (err) {
      console.log(extractError(err))
      this.unregisterError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.errorTitle = t[extractError(err) + '_TITLE']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  addFile(files) {
    this.uploadError = ''
    files.forEach(file => this.files.push(file))
    this.previewImage = true
  }

  async submitImage(type) {
    this.loading = true

    try {
      var formData = new FormData()
      formData.append('contractNumber', this.model.contractNumber)
      formData.append('file_0', this.files[0], this.files[0].name)
      const req = await api
        .post('/api/user-files/' + type, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      this.files = []
      this.loading = false
      this.uploadError = ''
      this.uploaded = true
      this.previewImage = false
      window.scrollTo(0, 0)
      return req
    } catch (err) {
      this.loading = false
      this.files = err.crossDomain ? [] : this.files
      this.uploadError = err.crossDomain ? t.LOYALTY_EXTERNAL_FILE_ERROR : t[extractError(err)] || t['ERROR']
      this.previewImage = !err.crossDomain
    }
  }

  async submitRedeemCode(e) {
    e.preventDefault()
    this.loading = true
    if (!this.code) {
      this.codeUploadError = t.LOYALTY_CODE_EMPTY
      this.loading = false
    } else {
      const formData = new FormData()
      formData.append('serialNumber', this.user.loyaltySerialNumber)
      formData.append('couponCode', this.code)
      try {
        const req = await api
          .post('/api/loyalty/redeem-code', formData, { headers: {
            'Content-Type': 'multipart/form-data'}
          })
        this.pointsAwarded = req.data.points
        this.codeUploadError = ''
        this.code = ''
        this.codeUploaded = true
        this.redeemCode = false
        this.getLoyaltyProgramViewData(this.user.loyaltySerialNumber)
      } catch (err) { 
        const error = extractError(err)
        this.loading = false
        let STATUS
         if(error === 'LIMIT_EXCEEDED') STATUS = 'LIMIT_EXCEEDED'
         else
         switch (error.message) {
          case 'Code already redeemed.':
            STATUS = 'ALREADY_REDEEMED'
            break
          case 'Code nicht mehr gültig':
            STATUS = 'ALREADY_REDEEMED'
            break
          case 'Code nicht vorhanden':
            STATUS = 'NOT_ACCEPTABLE'
            break
          default:
            STATUS = 'NOT_FOUND'
        }
        if (STATUS) {
          this.codeUploadError = extractErrorFromSap(err) || t['LOYALTY_CODE_ERROR_' + STATUS] || t['ERROR']
        }
        this.code = ''
        this.loading = false
      }
    }
  }

  async getRegistrationDate(contractNumber) {
    this.loading = true
    try {
      const { data } = await api.get(`/api/loyalty/${contractNumber}/check`)
      this.createdAt = data.registrationDate
      this.loading = false
    } catch (err) {
      console.log(extractError(err))
      this.accountError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.errorTitle = t[extractError(err) + '_TITLE']
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      this.loading = false
      window.scrollTo(0, 0)
    }
  }

  async redeemPoints() {
    try {
      await api
        .post('/api/loyalty/redeem-points', {
          serialNumber: this.user.loyaltySerialNumber
        })
      this.showThankYou = true
    } catch (err) {
      console.log('redeeming points failed')
      this.cashOutError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
    }
  }
}

export default LoyaltyStore
