import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import MobxReactForm from 'mobx-react-form'
import validator from 'validatorjs'
import userFields from '../../components/userFields'
import pick from 'lodash/pick'
import { extractResponse, extractError } from '../../service/response.service'
import { api, API_BASE } from '../../service/api.service'
import t from '../../service/translate.service'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { Input } from '../../components/formComponents'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

const FORGOT_PASSWORD_ENDPOINT = `${API_BASE}/api/auth/forgot-password`
const fields = pick(userFields, ['email'])
const plugins = { dvr: validator }

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.form = new MobxReactForm({ fields }, { plugins })
    extendObservable(this, { formSubmitted: false, notFound: false, error: false, errorMsg: '' })
  }

  onSubmit = (e) => {
    e.preventDefault()

    this.form.validate({ showErrors: true })
      .then(({ isValid }) => {
        if (!isValid) {
          return
        }
        const formData = this.form.values()
        this.error = false
        return api.post(FORGOT_PASSWORD_ENDPOINT, { ...formData })
          .then(extractResponse)
          .then(() => {
            this.formSubmitted = true
          })
          .catch(err => {
            this.error = true
            if (err.message === 'Not Found') {
              this.notFound = true
              return this.notFound
            }
            if (t[extractError(err)]) {
              this.errorMsg = t[extractError(err)]
            } else this.errorMsg = t.RESEND_VERIFICATION_EMAIL_ERROR
            throw extractError(err, 'Error occured when sending data to the server')
          })
      })
      .catch(err => {
        this.form.invalidate(err)
      })
  }

  render() {
    return this.formSubmitted ? this.renderResponse() : this.renderForm()
  }

  renderResponse() {
    return (<Alert message={t.FORGOT_PASSWORD_SUCCESS_MSG} type='success' style={{ marginTop: '12px', marginBottom: '12px' }} />)
  }

  renderForm() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Input
          label={this.form.$('email').label}
          value={this.form.$('email').value}
          onChange={this.form.$('email').sync}
          validate={this.form.$('email').error}
        />

        <Form.Group className='actions text-center'>
          <Link to='/login' onClick={() => historyService.push('/login')}>{t.RETURN_TO_LOGIN}</Link>
          <div className='text-center'>
            <Button type='submit' className='button primary' disabled={this.loader}>{t.PASSWORD_RESET_LINK}</Button>
          </div>
        </Form.Group>
        {this.error && <Alert message={this.errorMsg} type='error' style={{ marginTop: '12px', marginBottom: '12px' }} />}
      </Form>

    )
  }
}

export default observer(ForgotPasswordForm)
