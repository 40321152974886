import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from '../../service/translate.service'
import { Input } from '../../components/formComponents'
import { extendObservable } from 'mobx'
import AddressSelect from '../../components/AddressSelect'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Alert } from 'antd'
import { Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import User from '../../store/user.store'
import YouTube from 'react-youtube'
import withRouter from '../../components/withRouter'

class AddContract extends Component {
  static propTypes = {
    contractStore: PropTypes.object.isRequired,
    onboarding: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.props.contractStore.newContract()
    extendObservable(this, {
      submitSuccess: false,
      err: null,
      thankYouText: t.ADD_CONTRACT_THANK_YOU_TEXT
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  onSubmit = e => {
    e.preventDefault()
    this.props.contractStore
      .submitContract()
      .then((res) => {
        if (res !== 'err') {
          this.thankYouText = t.ADD_CONTRACT_THANK_YOU_TEXT
          this.submitSuccess = true
        }
      })
      .catch(err => {
        this.err = err
      })
  }

  onManualSubmit = e => {
    e.preventDefault()
    this.props.contractStore
      .submitContract(true)
      .then((res) => {
        if (res !== 'err') {
          this.thankYouText = t.ADD_CONTRACT_MANUAL_THANK_YOU_TEXT
          this.submitSuccess = true
          this.props.contractStore.newContract()
          this.err = null
          this.addressSelectRef = null
        }
      })
      .catch(err => {
        console.log(err)
        this.err = err
      })
  }

  handleBack = (e) => {
    this.props.menuStore.setCurrentPage('/')
    this.props.navigate('/')
  }
  handleCancel = () => {
    this.props.navigate('/')
  }

  isTenant = () => {
    return User.user.tenant
  }

  render() {
    const { contractStore, onboarding } = this.props
    const loading = contractStore.creatingContract
    const isManual = this.err && this.err.message !== 'CONTRACT_EXISTS'
    const istenant = this.isTenant()
    const youtubeOptions = {
      height: '250',
      width: '400'
    }
    return (
      <div className='subpageContent'>
        {this.submitSuccess ? (
          <ThankYouOverlay
            title={t.THANK_YOU}
            description={this.thankYouText}
            buttonText={t.BACK_HOME}
            buttonLink='/'
            handleClick={this.handleBack}
          />
        ) : (
          <div>
            <div className='title'>{t.ADD_A_CONTRACT_BUTTON}</div>
            <div className='subtitle'>
              {t.ADD_CONTRACT_SUBTITLE}
            </div>
            {this?.props?.contractStore?.error && (
              <Alert message={this?.props?.contractStore?.error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
            )}
            <Form>
                <Input
                  name='contractNumber'
                  label={t['USER_TENANT_NUMBER']}
                  type='text'
                  value={contractStore.contract.contractNumber}
                  onChange={e =>
                    contractStore.setVal('contractNumber', e.target.value)
                  }
                  helper={<span>{t.CONTRACT_INFO}</span>}
                  validate={contractStore.validators.contractNumber}
                />
                <AddressSelect
                  label={t.USER_ADDRESS}
                  onChange={address => contractStore.setVal('address', address)}
                  ref={ref => { this.addressSelectRef = ref }}
                  validate={contractStore.validators.address(
                    contractStore.contract.address,
                    t['USER_ADDRESS']
                  )}
                />

              <Form.Group className='actions text-center'>
                <Button
                  type='submit'
                  className='button primary'
                  onClick={this.onSubmit}
                >
                  {isManual ? t.ADD_CONTRACT_AGAIN : t.ADD_CONTRACT_BUTTON}
                </Button>
                {isManual && (
                  <Button
                    type='submit'
                    className='button primary'
                    onClick={this.onManualSubmit}
                  >
                    {t.MANUAL_APPROVAL_SUBMIT}
                  </Button>)}
                {onboarding && (<Button
                  className='button secondary'
                  onClick={this.handleCancel}
                >
                  {t.DO_THIS_LATER_BUTTON}
                </Button>)}
              </Form.Group>
            </Form>
            {onboarding && istenant && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <YouTube videoId='oHoag5w9Awc' opts={youtubeOptions} />
              </div>
            )}
            {loading && <LoadingPageOverlay />}
          </div>
        )}
      </div>
    )
  }
}

AddContract.propTypes = {
  menuStore: PropTypes.object
}
export default inject('contractStore', 'menuStore')(withRouter(observer(AddContract)))
