import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import ContractSelector from '../../components/contractSelector'
import withRouter from '../../components/withRouter'
import { OfficeWorkingHours } from './OfficeWorkingHours'
import t from '../../service/translate.service'
import { Alert } from 'antd'

class NewConsultationPage extends Component {

    constructor(props) {
        super(props)
        this.store = this.props.consultationStore
        this.navigate = this.props.navigate
        this.store.getAll()
        this.store.getPropertyManagers()
    }

    render() {
        return(
            <div>
                {this.store.loading && (<LoadingPageOverlay />)}
                <ContractSelector
                    getConsultations={() => {
                    this.store.success = false
                    this.store.getAll()
                    this.store.getPropertyManagers()
                }}/>
                <div style={{marginTop: 50}}>
                    {this.store.error ? (
                        <div style={{ marginBottom: 15 }}>
                            <Alert message={this?.store?.error || t['ERROR']} type='error' />
                        </div>
                    ) : (
                        this.store.consultations.length > 0 ?
                        
                        <OfficeWorkingHours offices={this.store.consultations} />
                
                        :

                        <div className='description'>
                            {t['CONSULTATIONS_NO_OFFICE_1']} <a href={`tel:+493034655777`}>{t['CONSULTATIONS_NO_OFFICE_2']}</a>
                        </div>
                        
                    )}
                    
                    
                </div>
                <div style={{marginTop: '50px'}}>
                    <p style={{color: '#a0a7aa'}}>{t.CONSULTATIONS_PAGE_DISCLAIMER}</p>
                </div>
            </div>
            
        )
    }
}

export default inject('consultationStore', 'contractStore')(withRouter(observer(NewConsultationPage)))