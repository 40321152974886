import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import t from '../../service/translate.service'
import { extendObservable } from 'mobx'
import HeatingItem from './HeatingItem'
import HeatingAndEnergyStore from '../../store/heatingAndEnergy.store'
import HeatingImage from './assets/heatingIcon.png'
import WarmWaterImage from './assets/waterIcon.png'
import CalendarIcon from '../../components/icons/calendar'
import PrimaryTitle from '../../components/PrimaryTitle'
import DatePicker, { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import enUS from 'date-fns/locale/en-US'
import 'react-datepicker/dist/react-datepicker.css'
import './css/index.css'
import ContractSelector from '../../components/contractSelector'
import { HeatingAndEnergyRegister } from './HeatingAndEnergyRegister'
import { HeatingAndEnergyConfirm } from './HeatingAndEnergyConfirm'
import { Checkbox } from '../../components/formComponents'
import { HeatingAndEnergyUnregister } from './HeatingAndEnergyUnregister'
import EnergyIcon from './assets/energyIconLong.png'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Link } from 'react-router-dom'
import { ContractsImage } from '../../components/icons'
import NoContent from '../../components/NoContent'
import historyService from '../../service/history.service'
import { Alert } from 'antd'
import withRouter from '../../components/withRouter'
import propTypes from "prop-types";

class HeatingAndEnergy extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
    consumptionStore: propTypes.object.isRequired,
  };
  constructor(props) {
    super(props)
    this.store = new HeatingAndEnergyStore()
    this.state = {
      showConfirmModal: false
    }
    this.contractStore = this.props.contractStore
    this.consumptionStore = this.props.consumptionStore;
    this.locale = localStorage.getItem('locale') || 'de'
    extendObservable(this, {
      heatingData: this.store.heatingData,
      warmWaterData: this.store.warmWaterData,
      isEnergyConsumptionEnabled: this.consumptionStore.energyToApp
    })
  }

  componentDidMount() {
    if (this?.contractStore?.contracts) {
      (async () => {
        const contracts = this?.contractStore?.contracts
        this.store.setLoading(true)
        this.store.setLoaded(false)
        this.locale === 'de' ? registerLocale('de', de) : registerLocale('en', enUS)
        for (let i = 0; i < contracts.length; i++) {
          const contract = contracts[i];
          const result = await this.store.setIsEnergyConsumptionEnable(contract, true)
          if(result) break
        }
        this.store.setLoading(false) 
        this.store.setLoaded(true)
      })()
    }
  }

  render() {
    const {
      heatingData,
      warmWaterData,
      model,
      setVal,
      isEnergyConsumptionEnabled,
      onCheckboxChange,
      energyConsumptionUnregisterModal,
      onUnregisterCancel,
      onRegister,
      loading,
      loadingData,
      loaded,
      loadingPdfDownload,
      errorRegistration,
    } = this.store
    if (!this.props.contractStore.selectedContract) {
      return (
        <div>
          <div className='text-center'>
            <Link to='/contracts/add-contract' className='button primary' style={{ marginBottom: '30px' }} onClick={() => historyService.push('/contracts/add-contract')}>
              {t.ADD_A_CONTRACT_BUTTON}
            </Link>
          </div>
          <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
        </div>
      )
    }
    if (energyConsumptionUnregisterModal) {
      return (
        <HeatingAndEnergyUnregister
          error={errorRegistration}
          loading={loading}
          handleCancel={onUnregisterCancel}
          handleClick={async () => {
            await onRegister(this.contractStore.selectedContract, false)
            this.props.navigate('/')
          }}
        />
      )
    }

    if (this.state.showConfirmModal) {
      return (
        <HeatingAndEnergyConfirm
          handleCancel={() => {
            this.setState({ ...this.state, showConfirmModal: false })
            this.props.navigate('/')
          }}
          handleClick={async () => {
            await onRegister(this.contractStore.selectedContract, true)
            this.setState({ ...this.state, showConfirmModal: false })
          }}
        />
      )
    }
    
    if (!isEnergyConsumptionEnabled) {
      return (
        <HeatingAndEnergyRegister
          setIsEnergyConsumptionEnable={this.store.setIsEnergyConsumptionEnable}
          contract={this.contractStore.selectedContract}
          loading={loading}
          isEnergyConsumptionEnabled={isEnergyConsumptionEnabled}
          loaded={loaded}
          error={errorRegistration}
          handleClick={async () => {
            this.setState({ ...this.state, showConfirmModal: true })
          }}
          handleCancel={() => {
            this.setState({ ...this.state, showConfirmModal: false })
            this.props.navigate('/')
          }}
        />
      )
    }

    return (
      <div>
        {loadingData || loadingPdfDownload && (
          <LoadingPageOverlay />
        )}
        <PrimaryTitle title={t.HEATING_AND_ENERGY_TITLE} />
        <div className='energy-heating-holder'>
          <div className='energy-contract-picker'>
            <ContractSelector label padding />
          </div> 
          <div className='energy-date-picker'>
            <DatePicker
              maxDate={this.store.subtractMonths(new Date(), 1)}
              minDate={this.store.subtractMonths(new Date(), 12)}
              selected={model.datePeriod}
              dateFormat='MMMM, yyyy'
              locale={this.locale}
              showMonthYearPicker
              adjustDateOnChange
              onChange={(date) => { setVal('datePeriod', date) }}
              />
              <div className='icon-wrap'>
                {CalendarIcon}
              </div>
          </div>
          {model.error ? (
            <Alert message={model.error} type='error' style={{ marginBottom: '62px', marginTop: '12px' }} />
          ) : (!model.dataAvailable || model.noData ? (
            <div className='energy-no-data'>
              <p>{model.noData ? t.HEATING_AND_ENERGY_EMPTY_RESULTS : t.HEATING_AND_ENERGY_NO_DATA}</p>
              <div className='energy-no-data-img-holder'>
                <img src={EnergyIcon} />
              </div>
            </div>
          ) : (
            <div className='energy-heating-items-holder'>
              {(heatingData.consumption || heatingData.previousMonth || heatingData.currentMonthLastYear || heatingData.comparison) && (
                <HeatingItem image={HeatingImage} head={t.HEATING_AND_ENERGY_HEATING} data={heatingData} type='heating' />
              )}
              {(warmWaterData.consumption || warmWaterData.previousMonth || warmWaterData.currentMonthLastYear || warmWaterData.comparison) && (
                <HeatingItem image={WarmWaterImage} head={t.HEATING_AND_ENERGY_WARM_WATER} data={warmWaterData} type='warm-water' />
              )}
            </div>
          ))}
          <div className='energy-actions-wrapper'>
            <div className='checkbox-holder'>
              <Checkbox
                labelBeforeText={t.HEATING_AND_ENERGY_CHECKNOX_TEXT}
                value={isEnergyConsumptionEnabled}
                onChange={() => onCheckboxChange(!isEnergyConsumptionEnabled)}
              />
            </div>
            {!loadingData && 
            <button className='button tertiary' onClick={() => this.store.downloadEnergyDocument()}><label style={{cursor: 'pointer'}}>UVI herunterladen</label></button>}
          </div>
        </div>
      </div>
    )
  }
}

export default inject('contractStore', "consumptionStore")(withRouter(observer(HeatingAndEnergy)))
