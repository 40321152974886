import { api } from '../service/api.service'
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import { extendObservable } from 'mobx'
import t from '../service/translate.service'
import find from 'lodash/find'
import User from '../store/user.store'

const CONTRACT_ENDPOINT = `/api/contract`

class ContractStore {
  constructor() {
    extendObservable(this, {
      contracts: [],
      loading: false,
      creatingContract: false,
      activeContracts: [],
      contract: {
        contractNumber: '',
        address: ''
      },
      isRabFetched: false,
      submitted: false,
      selectedContract: null,
      selectedRentAccContract: null,
      ready: false,
      error: '',
      accountHistoryError: '',
      rentContractDocumentsEnabled: false,
      operationalCostPrepaymentEnabled: false,
      contractDetailsError: '',
      manual: false,
      rentDueDateOptIn: false,
      rentAccountEnabled: false,
      ocCalculationsEnabled: false,
      coldCosts: '',
      operationalCosts: '',
      heatingCosts: '',
      contractStartDate: '',
      contractEndDate: '',
      tenants: [],
      totalCost: ''
    })

    this.initContracts()
  }
  initContracts() {
    this.initConfig()
    this.fetchContracts()
      .then(() => {
        this.selectedContract = this.activeContracts[0] || null
        this.ready = true
      })
      .catch(() => {
        this.ready = true
      })
  }

  changeContract(id) {
    this.selectedContract = find(this.contracts, { id })
  }

  get validators() {
    return {
      contractNumber: (val, label) => {
        if (this.submitted && !val) {
          return t['VALIDATE_REQUIRED'](label)
        }
        if (this.submitted && !/^[a-zA-Z0-9\s/-]+$/.test(val)) {
          return t['VALIDATE_CONTRACT_NUMBER']
        }
      },
      address: (val, label) =>
        this.submitted && !val && t['VALIDATE_ADDRESS'](label)
    }
  }

  newContract() {
    this.submitted = false
    this.creatingContract = false
    this.error = ''
    this.contract = {
      contractNumber: '',
      address: ''
    }
  }

  setVal(field, val) {
    this.contract[field] = val
  }
  async initConfig () {
    try {
      const res = await api.get('/api/config')
      this.rentAccountEnabled = res.data.settings.rentAccountEnabled
      this.rentContractDocumentsEnabled = res.data.settings.rentContractDocumentsEnabled
      this.ocCalculationsEnabled = res.data.settings.ocCalculationsEnabled
      this.operationalCostPrepaymentEnabled = res.data.settings.operationalCostPrepaymentEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async getConsumptionRegistrationInfo(contractNumber) {
    try {
      this.loading = true;
      const res = await api.get(`/api/consumption/${contractNumber}/info`);
      this.rentDueDateOptIn = res.data?.overdueToApp;
      this.loading = false;
      return this.rentDueDateOptIn;
    } catch (err) {
      this.loading = false;
      console.log('error while fetching rent due opt in:', err);
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'];
      throw new Error(extractError(err));
    }
  }


  async consumptionRegister(contract, optIn, optingFor) {
    try {
      this.loading = true
      await api.post(`/api/consumption/register`, {
        appId: contract.id,
        consumptionInfoViaApp: optIn,
        contract_number: contract.contractNumber,
        optingFor: optingFor })
      this.loading = false
    } catch (err) {
      this.loading = false
      console.log('error while updating rent due opt in:', err)
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      throw new Error(extractError(err))
    }
  }

  async fetchContracts() {
    this.selectedRentAccContract = null
    this.loading = true
    this.isRabFetched = false
    return api.get('/api/contract').then(async res => {
      const contracts = extractResponse(res)
      this.loading = false
      this.contracts = contracts
      this.isRabFetched = true
      this.activeContracts = contracts.filter(c => c.active && c.valid)
      return contracts
    })
  }

  async fetchAccountBalance(contract) {
    this.accountHistoryError = ''
    this.loading = true
    await api.get(`/api/rentalaccount/${contract.contractNumber}/balance`)
    .then((res) => {
      const data = extractResponse(res)
      if (data?.account_lines?.length > 0) {
        let amount = 0
        for (let i = 0; i < data?.account_lines?.length; i++) {
          amount = amount + data?.account_lines[i]?.amount || 0
        }
        contract.rentAccountBalance = amount
      } else {
        contract.rentAccountBalance = 0
      }
      this.loading = false
    })
    .catch((err) => {
      this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_ERROR']
      this.loading = false
      contract.rentAccountBalance = ''
      console.log(err)
    })
  }

  async fetchAccountHistory(contractNumber) {
    this.accountHistoryError = ''
    try {
      this.loading = true
      const res = await api.get(`/api/rentalaccount/${contractNumber}/balance`)
      const data = extractResponse(res)
      this.selectedRentAccContract = data?.account_lines || []
      this.loading = false
      if(data?.account_lines?.length < 1) {
        this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_EMPTY']
      }
    } catch (error) {
      this.accountHistoryError = t['RENT_ACCOUNT_HISTORY_ERROR']
      this.loading = false
      console.log('Error while fetching account history:', error)
    }
  }

  async submitContract(manual = false) {
    this.submitted = true
    this.manual = manual
    if (find(this.validators, (v, k) => v(this.contract[k]))) {
      return new Promise((resolve, reject) => {
        resolve('err')
      })
    }

    this.creatingContract = true
    return api
      .post(CONTRACT_ENDPOINT, Object.assign({}, this.contract, { manual }))
      .then(res => {
        const contract = extractResponse(res)
        this.contracts.push(contract)
        if (contract.valid) {
          this.activeContracts.push(contract)
          this.selectedContract = contract
        }
        User.contracts.push(contract)
        this.creatingContract = false

        User.getProfile()
        return contract
      })
      .catch((err, a) => {
        this.creatingContract = false
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        throw new Error(extractError(err))
      })
  }

  formatCosts(cost) {
    return `${cost.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
    }).replace('-', '')} €`
  }

  async getContractDetails(contractNumber) {
    this.contractDetailsError = ''
    try {
      const res = await api.get(`/api/contract/${contractNumber}/contract-overview/details`)
      if(res) {
        const data = extractResponse(res)
        this.coldCosts = this.formatCosts(data.coldRent)
        this.operationalCosts = this.formatCosts(data.operationalCosts)
        this.heatingCosts = this.formatCosts(data.heatingCosts)
        this.contractStartDate = data.contractStartDate
        this.contractEndDate = data.contractEndDate
        this.tenants = data.tenants
        this.totalCost = this.formatCosts(data.totalCost)
      }
    } catch(err) {
      this.contractDetailsError = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      throw new Error(extractError(err))
    }
  }
}

export default ContractStore
