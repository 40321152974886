import React from 'react'
import { Navigate, BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import Welcome from './pages/Welcome'

// Pages
import App from './App'
import NewsletterSubscribeConfirm from './pages/Profile/NewsletterSubscribeConfirm'
import { EditEmail, EditPassword, EditProfile, ViewProfile } from './pages/Profile'
import { FeedbackPage } from './pages/Feedback'
import LoginPage from './pages/LoginPage'
import RegistrationPage from './pages/RegistrationPage'
import { ForgotPasswordPage, ResetPasswordPage } from './pages/Password'
import NotFoundPage from './pages/NotFoundPage'
import { RepairsAndInquiries, RepairsAndInquiriesNewRequest } from './pages/RepairsAndInquiries'
import OfferItem from './pages/OfferItem'
import { Documents } from './pages/Documents'
import DocumentForm from './pages/Documents/DocumentForm/DocumentForm'
import { UserList, UserPage } from './pages/admin/Users'
import { SapInvalidCallsList } from './pages/admin/SapInvalidCalls'
import { PartnersList, PartnerEdit } from './pages/admin/Partners'
import { PartnerCategoriesList, PartnerCategoryEdit } from './pages/admin/PartnerCategories'
import { FaqQuestionsList, FaqQuestionEdit } from './pages/admin/FaqQuestions'
import { FaqCategoriesList, FaqCategoryEdit } from './pages/admin/FaqCategories'
import { InboxMessagesList, InboxMessagesEdit, InboxMessagesDetails } from './pages/admin/InboxMessages'
import {ReceiptInspection, ReceiptInspectionPreview} from "./pages/ReceiptInspection";
import RegistrationThankYou from './components/RegistrationThankYou'
import get from 'lodash/get'
import { AddContract, ContractsPage, Prepayments } from './pages/Contracts'
import  Letters from './pages/Letters'
import Onboarding from './pages/Onboarding'
import TenantServices from './pages/TenantServices'
import { Benefits, Offers } from './pages/Benefits'
import { InboxPage } from './pages/Inbox'
import ImprintPage from './pages/ImprintPage'
import TermsAndConditionsPage from './pages/TermsAndConditionsPage'
import { LoyaltyPage, LoyaltyFaq } from './pages/Loyalty'
import { UserActivityPage } from './pages/admin/UserActivity'
import { FaqCategories, FaqList } from './pages/faq'
import HeatingAndEnergy from './pages/HeatingAndEnergy/HeatingAndEnergy'

// Stores
import User from './store/user.store'
import ServerErrorPage from './pages/ServerErrorPage'
import EmailChanged from './pages/Profile/EmailChanged'
import EmailRevoked from './pages/Profile/EmailRevoked'
import withRouter from './components/withRouter'
import historyService from './service/history.service'
import { AdventEdit, AdventsList } from './pages/admin/Advents'
import { AppSettingsEdit, AppSettingsList } from './pages/admin/AppSettings'
import RentAccountHistory from './pages/Contracts/RentAccountHistory'
import DeleteProfile from './pages/Profile/DeleteProfile'
import NewConsultationPage from './pages/Consultations/NewConsultationPage'
import ContractOverview from './pages/Contracts/ContractOverview'
import OcCalculations from "./pages/Contracts/OcCalculations";
import OcCalculationsPreview from "./pages/Contracts/OcCalculationsPreview";
import RentContractDocuments from './pages/RentContractDocuments/RentContractDocuments'
import RentContractDocumentsPreview from './pages/RentContractDocuments/RentContractDocumentsPreview'
import ViewDocument from './pages/Documents/ViewDocument'

function authRequired(nextState, replace) {
  if (!User.user) {
    replace('/register')
  }

  if (get(User, 'user.admin', false)) {
    replace('/admin/user')
  }

  if (get(User, 'user.operator', false)) {
    replace('/admin/consultation')
  }
}

function operaterOrAdminRequired(nextState, replace) {
  if (!User.user) {
    replace('/register')
  }

  if (!get(User, 'user.admin', false) && !get(User, 'user.operator', false)) {
    replace('/')
  }
}
function adminRequired(nextState, replace) {
  if (!User.user) {
    replace('/register')
  }

  if (!get(User, 'user.admin', false)) {
    replace('/')
  }
}

function redirectIfLoggedIn(nextState, replace) {
  if (User.user) {
    replace('/')
  }
}

function IfForgotPassword(nextState, replace) {
  if (User.user) {
    replace('/profile/edit/password')
  }
}

const ProtectedRoute = ({ element: Component, ...props }) => {
  if (!User.user) {
    historyService.push('/register')
    return <Navigate to={{ pathname: '/register', state: { from: props.location } }} />
  } else if (
    User.user?.admin
  ) {
    historyService.push('/admin/user')
    return <Navigate to={{ pathname: '/admin/user', state: { from: props.location } }} />
  } else if (
    User.user?.operator
  ) {
    historyService.push('/admin/consultation')
    return <Navigate to={{ pathname: '/admin/consultation', state: { from: props.location } }} />
  } else {
    return <Outlet />
  }
}

const AdminRoute = ({ element: Component, ...props }) => {
  if (!User.user) {
    historyService.push('/login')
    return <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
  } else if (
    !User.user?.admin
  ) {
    historyService.push('/')
    return <Navigate to={{ pathname: '/', state: { from: props.location } }} />
  } else {
    return <Outlet />
  }
}

export default () => {
  const LoginPageWithRouter = withRouter(LoginPage)
  const RepairsAndInquiriesWithRouter = withRouter(RepairsAndInquiries)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<ProtectedRoute />}>
            <Route index element={<Welcome />} />
            <Route path='profile/edit' element={<EditProfile />} />
            <Route path='profile/edit/email' element={<EditEmail />} />
            <Route path='profile/edit/password' element={<EditPassword />} />
            <Route path='profile/delete-account' element={<DeleteProfile />} />
            <Route path='profile' element={<ViewProfile />} />
            <Route path='repairs-and-inquiries/new-request' element={<RepairsAndInquiriesNewRequest />} />
            <Route path='repairs-and-inquiries' element={<RepairsAndInquiriesWithRouter />} />
            <Route path='feedback/:rating' element={<FeedbackPage />} />
            <Route path='offer/:id' element={<OfferItem />} />
            <Route path='offers' element={<Offers />} />
            <Route path='consultations' element={<NewConsultationPage />} />
            <Route path='documents' element={<Documents />} /> 
            <Route path='documents/preview/:name' element={<ViewDocument />} /> 
            <Route path='documents/sign/:name' element={<DocumentForm />} /> 
            <Route path='heating-and-energy' element={<HeatingAndEnergy />} /> 
            <Route path='tenant-services' element={<TenantServices />} />
            <Route path='benefits' element={<Benefits />} />
            <Route path='faq' element={<FaqCategories />} />
            <Route path='faq/:id' element={<FaqList />} />
            <Route path='onboarding' element={<Onboarding />} />
            <Route path='onboarding/add-contract' element={<AddContract onboarding />} onboarding />
            <Route path='inbox' element={<InboxPage />} />
            <Route path='imprint' element={<ImprintPage />} />
            <Route path='terms-and-conditions' element={<TermsAndConditionsPage />} />
            <Route path='loyalty-program/faq' element={<LoyaltyFaq />} />
            <Route path='loyalty-program' element={<LoyaltyPage />} />
            <Route path='contracts/prepayments/:contractNumber' element={<Prepayments />} />
            <Route path='contracts/add-contract' element={<AddContract />} />
            <Route path='contracts' element={<ContractsPage />} />
            <Route path='letters' element={<Letters />} />
            <Route path='rent-account/:contractNumber' element={<RentAccountHistory />} />
            <Route path='contract-overview/:contractNumber' element={<ContractOverview />} />
            <Route path='receipt-inspection' element={<ReceiptInspection/>} />
            <Route path='receipt-inspection/:contractNumber/:caseNumber/:year/:docName/:docId/preview' element={<ReceiptInspectionPreview/>} />
            <Route path='rent-contract-documents/:contractNumber' element={<RentContractDocuments/>} />
            <Route path='rent-contract-documents/:contractNumber/:docId/preview' element={<RentContractDocumentsPreview/>} />
            <Route path='oc-calculations/:contractNumber' element={<OcCalculations />} />
            <Route path='oc-calculations/:contractNumber/preview/:docId' element={<OcCalculationsPreview />} />
          </Route>

          <Route path='confirm-newsletter-subscription/:id' element={<NewsletterSubscribeConfirm />} />
          <Route path='register' element={<RegistrationPage />} />
          <Route path='register-success' element={<RegistrationThankYou />} />
          <Route exact path='/login' element={<LoginPageWithRouter />} />
          <Route path='error' element={<ServerErrorPage />} />
          <Route path='change-email/:hash' element={<EmailChanged />} />
          <Route path='revoke-change-email/:hash' element={<EmailRevoked />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route path='reset-password/:hash' element={<ResetPasswordPage />} />

          <Route element={<AdminRoute />}>
            <Route path='admin/faq-categories' element={<FaqCategoriesList />} />
            <Route path='admin/faq-category/:id' element={<FaqCategoryEdit />} />
            <Route path='admin/faq-questions' element={<FaqQuestionsList />} />
            <Route path='admin/faq-question/:id' element={<FaqQuestionEdit />} />
            <Route path='admin/partner-categories' element={<PartnerCategoriesList />} />
            <Route path='admin/partner-category/:id' element={<PartnerCategoryEdit />} />
            <Route path='admin/partners' element={<PartnersList />} />
            <Route path='admin/partner/:id' element={<PartnerEdit />} />
            <Route path='admin/user' element={<UserList />} />
            <Route path='admin/user/:id' element={<UserPage />} />
            <Route path='admin/user-activity' element={<UserActivityPage />} />
            <Route path='admin/admin-alert' element={<InboxMessagesList />} />
            <Route path='admin/advents' element={<AdventsList />} />
            <Route path='admin/advent/:id' element={<AdventEdit />} />
            <Route path='admin/app-settings' element={<AppSettingsList />} />
            <Route path='admin/app-settings/:id' element={<AppSettingsEdit />} />
            <Route path='admin/admin-alert/:id' element={<InboxMessagesEdit />} />
            <Route path='admin/admin-alert-details/:id' element={<InboxMessagesDetails />} />
            <Route path='admin/sap-invalid-calls' element={<SapInvalidCallsList />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}



// 
// DONE <Route path='register-success' component={RegistrationThankYou} onEnter={redirectIfLoggedIn} /> 
// DONE <Route path='login' component={LoginPage} onEnter={redirectIfLoggedIn} /> 
// <Route path='admin/consultation' component={AdminConsultationsList} onEnter={operaterOrAdminRequired} /> 
//  <Route path='admin/scheduled-consultation' component={ScheduledConsultation} onEnter={operaterOrAdminRequired} />
// DONE <Route path='forgot-password' component={ForgotPasswordPage} onEnter={IfForgotPassword} />
// DONE <Route path='reset-password/:hash' component={ResetPasswordPage} />
// DONE <Route path='faq/:id' component={FaqList} />
// DONE <Route path='contracts/add-contract' component={AddContract} onEnter={authRequired} />
// DONE <Route path='contracts' component={ContractsPage} onEnter={authRequired} />
// DONE <Route path='onboarding/add-contract' component={AddContract} onboarding onEnter={authRequired} />
// DONE <Route path='*' component={NotFoundPage} /> */
